import { useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import SocialMediaIcons from "./SocialMediaIcons";
import ThreeDotSeperator from "./ThreeDotSeperator";
import Logo from "../public/logo.webp";
import { useRouter } from "next/router";
import ReactCountryFlag from "react-country-flag";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const t = useTranslations("Layout");
  const year = new Date().getFullYear();
  const router = useRouter();

  return (
    <div className="flex flex-col justify-between h-screen subpixel-antialiased">
      <header className="relative">
        <nav className="relative z-10 flex items-center justify-between px-6 py-6">
          <Link href="/">
            <Image
              src={Logo}
              alt="Dr. Birsel Uçkun Logo"
              placeholder="blur"
              width={199}
              height={50}
            />
          </Link>
          <div className="lg:hidden">
            <button
              className="flex items-center p-3 text-gray-400"
              onClick={() => setMobileMenu(true)}
            >
              <svg
                className="block w-4 h-4 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Mobile menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <ul className="absolute hidden transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 lg:flex lg:mx-auto lg:items-center lg:w-auto lg:space-x-6">
            <li>
              <Link href="/">
                <a
                  className={
                    router.asPath == "/"
                      ? "text-sky-600"
                      : "text-gray-400 hover:text-gray-500"
                  }
                >
                  {t("home")}
                </a>
              </Link>
            </li>
            <ThreeDotSeperator />
            <li>
              <Link href="/about" passHref>
                <a
                  className={
                    router.asPath == "/about"
                      ? "text-sky-600"
                      : "text-gray-400 hover:text-gray-500"
                  }
                >
                  {t("aboutUs")}
                </a>
              </Link>
            </li>
            <ThreeDotSeperator />
            <li>
              <Link href="/bioresonance" passHref>
                <a
                  className={
                    router.asPath == "/bioresonance"
                      ? "text-sky-600"
                      : "text-gray-400 hover:text-gray-500"
                  }
                >
                  {t("bioresonance")}
                </a>
              </Link>
            </li>
            <ThreeDotSeperator />
            <li>
              <Link href="/blog" passHref>
                <a
                  className={
                    router.asPath == "/blog"
                      ? "text-sky-600"
                      : "text-gray-400 hover:text-gray-500"
                  }
                >
                  {t("blog")}
                </a>
              </Link>
            </li>
          </ul>
          <div className="hidden md:flex md:items-center md:space-x-4">
            <div className="hidden md:block">
              <Link
                href={router.asPath}
                locale={router.locale === "en" ? "tr" : "en"}
              >
                <ReactCountryFlag
                  countryCode={router.locale === "en" ? "TR" : "GB"}
                  svg
                  style={{
                    width: "1.5em",
                    height: "1.5em",
                  }}
                />
              </Link>
            </div>
            <a
              className="hidden px-6 py-2 text-sm font-bold text-white transition duration-200 bg-primary lg:block hover:bg-sky-700 rounded-l-xl rounded-t-xl"
              href="#contact"
            >
              {t("contactUs")}
            </a>
          </div>
        </nav>
        <div
          className={`fixed top-0 bottom-0 left-0 z-50 w-5/6 max-w-sm navbar-menu ${
            mobileMenu ? "" : "hidden"
          }`}
        >
          <nav className="relative flex flex-col w-full h-full px-6 py-6 overflow-y-auto bg-white border-r">
            <div className="flex items-center mb-8">
              <Link href="/">
                <div className="mr-auto">
                  <Image
                    className="h-10"
                    src={Logo}
                    alt="Dr. Birsel Uçkun Logo"
                    placeholder="blur"
                    width={165}
                    height={85}
                  />
                </div>
              </Link>
              <button onClick={() => setMobileMenu(false)}>
                <svg
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div>
              <ul>
                <li className="mb-1">
                  <Link href="/" passHref>
                    <a className="block p-4 text-sm font-semibold text-gray-400 rounded hover:bg-sky-50 hover:text-sky-600">
                      {t("home")}
                    </a>
                  </Link>
                </li>
                <li className="mb-1">
                  <Link href="/about" passHref>
                    <a className="block p-4 text-sm font-semibold text-gray-400 rounded hover:bg-sky-50 hover:text-sky-600">
                      {t("aboutUs")}
                    </a>
                  </Link>
                </li>
                <li className="mb-1">
                  <Link href="/bioresonance" passHref>
                    <a className="block p-4 text-sm font-semibold text-gray-400 rounded hover:bg-sky-50 hover:text-sky-600">
                      {t("bioresonance")}
                    </a>
                  </Link>
                </li>
                <li className="mb-1">
                  <Link href="/blog" passHref>
                    <a className="block p-4 text-sm font-semibold text-gray-400 rounded hover:bg-sky-50 hover:text-sky-600">
                      {t("blog")}
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-auto">
              <div className="pt-6">
                <a
                  className="block px-4 py-3 mb-2 text-xs font-semibold leading-loose text-center text-white bg-primary hover:bg-sky-700 rounded-l-xl rounded-t-xl"
                  href="#contact"
                >
                  {t("contactUs")}
                </a>
              </div>
              <div className="text-center">
                <Link
                  href={router.asPath}
                  locale={router.locale === "en" ? "tr" : "en"}
                >
                  <ReactCountryFlag
                    countryCode={router.locale === "en" ? "TR" : "GB"}
                    svg
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                    }}
                  />
                </Link>
              </div>
              <p className="my-4 text-xs text-center text-gray-400">
                <span>
                  © {year} {t("copyright")}
                </span>
              </p>
              <div className="text-center">
                <SocialMediaIcons />
              </div>
            </div>
          </nav>
        </div>
      </header>
      <main className="mb-auto">{children}</main>
      <footer>
        <div className="py-20 bg-gray-50">
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap items-center justify-between pb-12 border-b border-gray-100">
              <div className="w-full mb-12 lg:w-1/5 lg:mb-4">
                <Link href="/">
                  <Image
                    src={Logo}
                    alt="Dr. Birsel Uçkun Logo"
                    placeholder="blur"
                    width={199}
                    height={50}
                  />
                </Link>
              </div>
              <nav className="w-full lg:w-auto">
                <ul className="flex flex-wrap items-center lg:space-x-5">
                  <li className="w-full mb-2 md:w-auto md:mb-0">
                    <Link href="/" passHref>
                      <a className="text-gray-400 lg:text-sm hover:text-gray-500">
                        {t("home")}
                      </a>
                    </Link>
                  </li>
                  <ThreeDotSeperator />
                  <li className="w-full mb-2 md:w-auto md:mb-0">
                    <Link href="/about" passHref>
                      <a className="text-gray-400 lg:text-sm hover:text-gray-500">
                        {t("aboutUs")}
                      </a>
                    </Link>
                  </li>
                  <ThreeDotSeperator />
                  <li className="w-full mb-2 md:w-auto md:mb-0">
                    <Link href="/bioresonance" passHref>
                      <a className="text-gray-400 lg:text-sm hover:text-gray-500">
                        {t("bioresonance")}
                      </a>
                    </Link>
                  </li>
                  <ThreeDotSeperator />
                  <li className="w-full mb-2 md:w-auto md:mb-0">
                    <Link href="/blog" passHref>
                      <a className="text-gray-400 lg:text-sm hover:text-gray-500">
                        {t("blog")}
                      </a>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="flex flex-wrap items-center justify-between mt-8">
              <p className="order-last text-sm text-gray-400">
                © {year} {t("copyright")}
              </p>
              <div className="order-first mb-4 lg:mb-0 lg:order-last">
                <SocialMediaIcons />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;

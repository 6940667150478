import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";

const SocialMediaIcons = () => (
  <>
    <Link href="https://www.facebook.com/drbirseluckun/" passHref>
      <a target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon
          className="inline-block p-2 mr-2 text-sky-600 rounded cursor-pointer bg-gray-50 hover:bg-gray-100"
          size="2x"
          icon={faFacebook}
        />
      </a>
    </Link>
    <Link href="https://www.instagram.com/bioresonance_uk08/" passHref>
      <a target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon
          className="inline-block p-2 mr-2 text-sky-600 rounded cursor-pointer bg-gray-50 hover:bg-gray-100"
          size="2x"
          icon={faInstagram}
        />
      </a>
    </Link>
  </>
);

export default SocialMediaIcons;
